import {useState} from "react";
import { useNavigate } from "react-router-dom";

function Buscador() {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();
    return (
        <div className="search-bar mx-0 order-xl-2 order-lg-1 order-1">
            <div className="container px-0">
                <div className="row buscador px-0 mx-0">
                    <div className="search-bar mx-0 ">
                        <input
                            type="search"
                            className="form-control me-lg-2 me-0 py-3"
                            id="query"
                            name="query"
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            placeholder="Buscar"
                            aria-label="Buscar"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    navigate(`/catalogo/buscar/${query}`, { replace: false });
                                    navigate(0);
                                }
                            }}
                        />
                        <button className={'btn desktop btn-buscador-icono'} onClick={() => {
                            navigate(`/catalogo/buscar/${query}`, { replace: false });
                            navigate(0);
                        }}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Buscador;