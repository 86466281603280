import {Carousel} from "react-bootstrap";

export function CarouselDesktop() {

    return (
        <Carousel interval={2000} className="carousel  px-0 maincarousel" fade={true}>
            <Carousel.Item>
                <img alt='' src="/img/carousel-1.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="/img/carousel-2.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="/img/carousel-3.png" className="d-block w-100" />
            </Carousel.Item>
        </Carousel>
    );
}

export function CarouselMobile() {
    return (
        <Carousel interval={2500} className="carousel px-0 maincarousel" fade={true}>
            <Carousel.Item>
                <img alt='' src="/img/carousel-1-mb.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="/img/carousel-2-mb.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="/img/carousel-3-mb.png" className="d-block w-100"/>
            </Carousel.Item>
        </Carousel>
    );
}